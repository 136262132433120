.profile-userinfo-container {
    display: block;
    height: auto;
    margin: 10px 32px;
}

.profile-userinfo-username {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    margin-right: 10px;

    img {
        height: 22px;
        width: 22px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.profile-userinfo-bio {
    display: flex;
    justify-content: center;
    height: 100px;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
    text-align: center;
}

.profile-userinfo-field {
    display: block;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    border: none;
    margin: 5px;
    outline: none;
    background: #cacac9;
}

.profile-userinfo-textarea {
    height: 100px;
    font-family: inherit;
    font-size: 16px;
    resize: none;
    color: #0433ff;
}


.profile-userinfo-field::placeholder {
    color: #0433ff;
}