
.profile-action-bar {
    display: flex;
    justify-content: space-evenly;

    a {
        color: unset !important;
        text-decoration: unset !important;
        background: unset !important;
        width: 100px;
    }

    img {
        height: 30px;
        width: auto;
        margin: 0 auto;
        display: block;
    }
}