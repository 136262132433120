
.ReactStickyHeader_fixed {
  left: unset !important;
  right: unset !important;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

header {
    min-height: 20px;
    width: 100%;
    padding: 4px 0px;
    background-color: black;
}

.header-scrollover-sticky-container {
    width: 100%;
}

.head-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "BRADHI";
    font-size: 24px;
    color: white;
}

.head-link-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

.head-link {
    position: relative;
    cursor: pointer;
    img {
        height: 28px;
        width: auto;
    }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
}

@media screen and (min-width: 500px) {
  .sidenav {
      height: 100%;
      left: 50%;
      margin-left: -250px;
  }
}

.sidenav-option {
  display: inline-flex;
  align-items: center;
  padding: 14px 8px 14px 32px;
  position: relative;
  width: 100%;

  .sidenav-option-cloud {
    width: 32px;
  }

  .sidenav-option-points {
    height: 35px;
  }

  .sidenav-option-subscription {
    margin-left: 5px;
  }

  .sidenav-option-logout {
    width: 32px;
    height: 60px;
    object-fit: cover;
    margin-left: -10px;
  }

  img {
    height: 24px;
    width: auto;
    object-fit: contain;
    position: absolute;
  }
}

.sidenav a {
  width: max-content;
  text-decoration: none !important;
  font-size: 18px;
  color: #818181;
  display: block;
  transition: 0.3s;
  position: relative;
  padding-left: 40px;
}

.sidenav-active-a {
  color: white !important;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav-icon-img {
  height: 22px;
  width: 22px;
  margin-right: 12px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav-divider {
  border: 1px solid #2d2d2d;
  width: 75%;
  margin: 5px auto;
}

.sidenav-footer-container {
  display: block;
  margin-top: 20px;

  .sidenav-footer-container-spread {
    display: flex;
    justify-content: space-evenly;

    a {
      margin: unset !important;
    }
  }

  a {
    display: block;
    margin: 0 auto;
    font-size: 13px;
    color: #797979;
    text-align: center;
    padding: 5px 0px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}