.landing-help-link-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.landing-help-link {
    display: flex;
    height: auto;
    width: auto;
    background-color: transparent !important;
    color: white !important;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-decoration: none !important;
    margin-bottom: 16px;
}