
.post-add-comment-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    :nth-child(3) {
        margin-left: 10%;
    }
}

.post-add-comment-profile {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 16px;
    box-shadow: 2px 2px 2px 0px #00000061;
}

.post-add-comment-content {
    display: inline-block;
}

.post-add-comment-img {
    height: 40px;
    width: calc(100% - 30%);
}

.post-add-comment-btn {
    height: 40px;
    width: 250px;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    background-size: 100% 100%;
    background-position: center;
}

.post-add-comment-label {
    color: black;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 0px;
}

