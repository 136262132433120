
.points-container {
    min-height: 100vh;
    background-color: #cacac9;
    padding: 0px 16px;
}

.points-options-container {
    display: block;
    height: 60px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 0px 16px;
    
    button {
        display: inline-block;
        height: 100%;
        width: 50%;
        border: none;
        outline: none;
        background: transparent;
        line-height: 22px;
        font-size: 16px;
        color: black;

        span {
            font-weight: 600;
            font-size: 22px;
        }
    }

    .pro-active {
        border-bottom: 2px solid black;
    }

    .pro-single {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.points-table {
    height: 100%;
    width: 100%;
    margin-bottom: 50px;
}

.points-redeem-btn-container {
    margin-bottom: 30px;
}

.points-redeem-btn {
    background: rgba(157,218,164,1);
    background: -moz-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(157,218,164,1)), color-stop(17%, rgba(93,195,105,1)), color-stop(27%, rgba(55,182,71,1)), color-stop(100%, rgba(55,182,71,1)));
    background: -webkit-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
    background: -o-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
    background: -ms-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
    background: linear-gradient(to bottom, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ddaa4', endColorstr='#37b647', GradientType=0 );

    display: block;
    height: 38px;
    width: 82px;
    font-size: 15px;
    font-family: "HRB";
    outline: none;
    border-radius: 6px;
    border: none;
    color: white;
    text-align: center;
    padding: 0;

    -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    text-shadow: 1px 1px 1px #000000;
    letter-spacing: .3px;
    margin: 0 auto;
}

.prb-inactive {
    background: darkgray;
    color: lightgray;
}