.help-form-content {
    display: table;
    margin: 10px auto;
}

.help-step-label {
    display: block;
    font-size: 19px;
    color: white;
    text-align: center;
}

.help-form-input {
    display: block;
    height: 36px;
    width: 226px;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: #797979;
    margin-bottom: 12px;
    outline: none;
    border-radius: 8px;
    border: none;
}

.help-form-input::placeholder {
    color: white;
}