
.admin-content-table {
    height: 100%;
    width: 100%;
    border-bottom: 2px solid #ddd;

    thead {
        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: blue;
            float: right;
            margin-right: 12px;
            font-size: 36px;
        }
    }
}

.admin-content-cell {
    
    border-top: 2px solid #ddd;
    * {
        cursor: pointer;
    }
}

.admin-content-cell-content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 45px;

    div {
        width: 120px;
        font-size: 16px;
        word-wrap: break-word;
    }

    span {
        font-weight: 600;
    }
}

.admin-content-options-container {
    display: block;
    height: 60px;
    width: 100%;
    margin-bottom: 30px;
    padding: 0px 16px;
    
    button {
        display: inline-block;
        height: 100%;
        width: 33%;
        border: none;
        outline: none;
        background: transparent;
        line-height: 22px;
        font-size: 16px;
        color: black;

        span {
            font-weight: 600;
            font-size: 22px;
        }
    }

    .pro-active {
        border-bottom: 2px solid black;
    }

    .pro-single {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}