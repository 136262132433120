

.store-front-top-container {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    label {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }

    button {
        height: 32px;
        width: 32px;
        position: absolute;
        top: -4px;
        right: 12px;
        border-radius: 50%;
        background: black;
        border: none;
        outline: none;
        color: white;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
    }
}

.store-front-table {
    height: 100%;
    width: 100%;
}

.store-front-info {

    img {
        display: block;
        width: 100%;
        background-color: transparent;
    }
}