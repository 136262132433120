
.store-front-cell {
    height: 100%;
    width: 100%;
    padding: 5px 0px;

    img {
        display: block;
        width: 100%;
        background-color: transparent;
    }

    button {
        display: block;
        float: right;
        color: #0433ff;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0px;
    }

    .img-loading {
        height: 200px;
    }
}

.store-front-cell-divider {
    display: block;
    height: 1px;
    width: 100%;
    background: black;
    margin: 0 auto;
    float: right;
}