.user-result-tablecell {
    height: 100%;
    width: 100%;
    position: relative;
    
}

.user-result-tr-content-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 92vw;
}

.user-result-tr-content {
    display: flex;
    width: 75%;
    align-items: center;
}

.user-result-tr-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.user-result-tr-userlabel {
    display: block;
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin: 0;

    img {
        height: 18px;
        width: 18px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.user-result-tr-biolabel {
    display: block;
    width: 100%;
    color: black;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.user-result-tr-label-container {
    margin-left: 12px;
    overflow: hidden;
}

.user-result-custom-action {

    img {
        height: 28px;
        width: auto;
        object-fit: contain;
    }
}