
.redeem-outlet-cell {
    height: auto;
    width: 100%;
    padding: 16px;

    hr {
        border: 1px solid black;
        margin: 0px;
    }
}

.redeem-outlet-cell-title {
    display: block;
    font-size: 15px;
    width: 100%;

    div {
        width: 100%;
    }

    label {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        font-weight: 600;
    }

    span {
        display: flex;
        justify-content: center;
    }
}

.redeem-outlet-cell-image-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px 0px;
   
    .awsimg-container {
        min-height: 50px;
        min-width: 50px;

        img {
            max-height: 140px;
            width: 100%;
            object-fit: contain;
        }
    }

}

.redeem-outlet-cell-desc {
    display: block;
    width: 100%;

    label {
        width: 100%;
        font-size: 15px;
        word-wrap: break-word;
        white-space: pre-line;
    }
}

.redeem-outlet-action-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    
    margin-top: 10px;
    margin-bottom: 5px;

    label {
        display: block;
        max-width: 150px;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
        text-align: left;
    }

    span {
        display: block;
        font-size: 15px;
        font-weight: 400;
    }

    .redeem-outler-action-label {
        max-width: 80px;
        color: #0433ff;
        cursor: pointer;
        text-align: center;
        
        span {
            font-weight: 600;
        }
    }
}