
.points-table-cell {
    width: 100%;
    padding-bottom: 5px;
}

.points-table-cell-info {
    font-size: 15px;

    label {
        cursor: pointer;
    }
}