

.admin-users-detail {
    .admin-users-options-container {
        margin-top: 15px;

        button {
            width: 33%;
        }
    }

    .post-sticker-container {
        display: none;
    }
}

.admin-users-detail-table {
    height: 100%;
    width: 100%;
    margin-bottom: 70px;

    tr {
        border-bottom: 5px solid white;
    }

    td {
        padding: 20px 0px;
    }
}

.admin-users-detail-report-labels {
    margin-top: 5px;

    label {
        display: block;
        font-size: 15px;
        margin-bottom: 0px;
    }

    label:first-letter {
        text-transform:capitalize;
    }

    span {
        font-weight: 600;
    }
}

.admin-users-detail-report-buttons {
    margin: 20px 0px 20px 0px;

    div {
        display: inline-flex;
        justify-content: space-evenly;
        width: 100%;
    }

    button {
        outline: none;
        border: none;
        width: 80px;
        height: 45px;
        border-radius: 8px;
        box-shadow: 0px 4px 3px 0px #000;
        color: white;
        -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        text-shadow: 1px 1px 1px #000000;
        letter-spacing: .3px;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
        padding: 0;
    }
}