$profileCircleSize: 75;
$centerProfileCircle: calc($profileCircleSize / 2);

.post-profile-full-container {
    height: 134px;
    width: 134px;
    margin-top: -21px;
    position: relative;
}

.post-image-full-badge {
    height: 134px;
    width: 134px;
    object-fit: cover;
}

.post-image-full-user {
    height: unquote($profileCircleSize + "px");
    width: unquote($profileCircleSize + "px");
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    border: 1px solid black;
    border-radius: 50%;
    background-color: white;
    box-shadow: 4px 4px 4px 0px #00000061;

    .awsimg-container {
        img {
            height: unquote($profileCircleSize + "px");
            width: unquote($profileCircleSize + "px");
            border-radius: 50%;
        }
    }
}
