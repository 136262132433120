.profile-action-item {
    height: 38px;
    width: auto;
    text-align: center;
    padding: 0px 10px;
    
    * {
        cursor: pointer;
    }

    span {
        font-size: 22px;
        font-weight: 700;
    }

    label {
        display: block;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }
}