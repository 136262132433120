
.register-steps-container {
    display: flex;
    justify-content: space-evenly;
    margin: 38px;
}

.register-step {
    color: #53585f;
    font-size: 20px;
    font-weight: 600;
    margin: 0px -18%;
    cursor: default;
}

.register-step-active {
    color: white;
}