
.logo-container {
    display: block;
}

.logo-img-lrg {
    display: block;
    height: 240px;
    width: auto;
    object-fit: contain;
}

.logo-img-med {
    display: block;
    height: 140px;
    width: auto;
    object-fit: contain;
    margin: 20px auto 18px auto;
}

.logo-img-small {
    display: block;
    height: 64px;
    width: auto;
    object-fit: contain;
}

.logo-text {
    display: block;
    color: white;
    text-align: center;
    font-size: 18px;
}