
.messages-page-container {
    background-color: #cacac9;
}

.messages-head-container {
    display: block;
    width: 100%;
    color: black;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    background-color: #cacac9;
    padding: 22px;
    margin-bottom: 1px;
    position: relative;
}

.messages-create-icon {
    height: 30px;
    width: auto;
    position: absolute;
    right: 10%;
    cursor: pointer;
}

.messages-head-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    left: 9%;
    top: 20px;
    cursor: pointer;

    img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
}

.messages-head-username {

    img {
        height: 18px;
        width: 18px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.mtc-converse {
    height: auto !important;

}

.messages-table-container {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 16px 0px;
}

.messages-table {
    height: 100%;
    width: 100%;

    td {
        padding: 8px 12px;
    }

    a { 
        text-decoration: none !important;
        background-color: transparent !important;
    }

    * {
        cursor: pointer;
    }
}

.messages-table-empty-label {
    text-align: center;
    padding: 0px 32px;
}

.messages-search-input-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    span {
        margin-right: 12px;
        padding-top: 5px;
    }

    input {
        height: 40px;
        width: 70%;
        background-color: #52585e;
        border-radius: 8px;
        outline: none;
        border: none;
        padding: 4px 16px;
        color: white;
        font-size: 16px;
        margin-right: 12px;
    }

    input::placeholder {
        color: white;
    }
}

.messages-text-input-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    input {
        height: 40px;
        width: 70%;
        background-color: #52585e;
        border-radius: 8px;
        outline: none;
        border: none;
        padding: 4px 16px;
        color: white;
        font-size: 16px;
        margin-right: 12px;
    }

    input::placeholder {
        color: white;
    }
}