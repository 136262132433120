
.message-bubble-tablecell {

}

.message-bubble {
    height: auto;
    min-height: 95px;
    width: 60vw;
    max-width: 270px;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    padding: 12px 16px;
}

.message-bubble-left {
    margin-left: 20px;
    float: left;
    background: #a6a9a9;
    color: black;
}

.message-bubble-left:before {
    content: "";
    position: absolute;
    top: 47.33%;
    left: -6.4%;
    height: 20px;
    width: 20px;
    background: #a6a9a9;
    box-sizing: border-box;
    transform: rotate(135deg) translate(-50%);
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

.message-bubble-right {
    margin-right: 20px;
    float: right;
    background: #0276e1;
    color: white;
}

.message-bubble-right:before {
    content: "";
    position: absolute;
    top: 32.33%;
    right: -6.4%;
    height: 20px;
    width: 20px;
    background: #0276e1;
    box-sizing: border-box;
    transform: rotate(-45deg) translate(-50%);
    border-bottom: inherit;
    border-right: inherit;
    box-shadow: inherit;
}

