
.post-text-container {
    width: calc(100vw - 20%);
    max-width: 365px;
    position: relative;
}

.post-text-cloud {
    height: auto;
    width: 100%;
}

.post-cloud-container {
    position: relative;
}

.post-cloud-img {
    height: auto;
    min-height: 145px;
    width: 98%;
    z-index: 0;
    object-fit: contain;
}

.post-cloud-img-mask {
    position: absolute;
    left: 0px;
    z-index: 2;
}

.post-scroll-indicator-label {
    position: absolute;
    right: -14px;
    top: 35%;
    font-size: 15px;
    font-weight: 600;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin: 0;
}

.post-text-area {
    position: absolute !important;
    top: 28%;
    left: 7%;
    width: 83.2%;
    height: 43%;
    z-index: 1;
    border: none;
    resize: none;
    background: transparent;
    outline: none;
    font-size: 16px;
    line-height: 20px;
}

.post-text-area::placeholder {
    color: #0433ff;
    font-weight: 600;
}

.post-text-area--multiLine {
    margin-top: 6%;
}

.post-text-area__control {
    height: 100%;
    width: 100%;
}
.post-text-area__input {
    overflow-y: scroll !important;
    outline: none;
    border: none;
    padding: 0% 6%;
}

.post-text-area__input::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

.post-text-area__input::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #5bade7;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

.post-text-area__input::placeholder {
    color: #0433ff;
    font-weight: 600;
}

.post-text-area__highlighter {
    height: 100%;
    width: 100%;
    padding: 0;
    padding: 0% 6%; 
}

.post-text-area__suggestions__list {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    max-height: 175px;
    overflow: auto;
}

.post-text-area__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.post-text-area__suggestions__item — focused {
    background-color: #daf4fa;
}


.post-text-view {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 47%;
    padding: 0% 6%;
    overflow-y: scroll;
    word-break: break-word;

    .mention {
        display: contents;
        text-decoration: none !important;
        cursor: pointer;
    }

}

.post-text-view::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}

.post-text-view::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #5bade7;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.post-text-username {
    padding: 0;
    overflow: hidden;

    label {
        font-weight: 600;
        font-size: 15px;
        width: 140px;
        margin: 0px;
        padding: 0;
        position: absolute;
        bottom: 1px;
    }

    img {
        height: 18px;
        width: 18px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.post-text-clouds-btn {
    text-align: right;
    font-weight: 600;
    font-size: 14px;
    color: #0433ff;
    margin-right: 14px;
    margin-top: 14px;

    label {
        margin-bottom: 0px;
        cursor: pointer;
    } 
}

.post-comment-actions-container {
    position: relative;
}

.post-text-date {
    text-align: center;
    width: 88%;

    time {
        font-size: 15px;
    }
}

.post-text-like {
    position: absolute;
    right: 0;
    top: 0;

    img {
        height: 20px;
        width: 20px;
        margin-right: 8px;
        cursor: pointer;
    }

    label {
        font-size: 15px;
        cursor: pointer;
    }
}