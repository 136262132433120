
.admin-outlets-codes-form {
    margin-top: 24px;
    padding: 0px 12px;

    div {
        min-height: 80px;
        width: 100%;
    }

    label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
    }


    input, textarea {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
    }

    textarea {
        height: 100px;
        padding: 12px;
        resize: none;
    }


    input::placeholder {
        color: white;
    }

    textarea::placeholder {
        color: white;
    }

    select {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }

    .csv-input {
        padding-top: 5px;
    }

}