.comments-table {
    height: 100%;
    width: 100%;
}

.comments-tablecell {
    padding: 12px !important;
    position: relative;
}

.comments-tc-profile {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-right: 10px;
}

.comments-tc-content {
    display: inline-flex;
    align-items: center;
    width: 100%;
    position: relative;

    .post-text-container {
        width: 100%;
    }
}