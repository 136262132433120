.post-container {
    min-height: 100vh;
    background-color: #cacac9;
    position: relative;
    overflow: hidden;
}

.create-post-submitbtn-container {
    margin-top: 90px
}

#post-feed-table {
    height: 100%;
    width: 100%;

    tr {
        border-bottom: 5px solid white;
    }

    tr:not(:first-child) {
        td {
            padding-top: 20px;
        }
    }

    td {
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.post-feed-related-tablerow {
    border-bottom: 3px transparent !important;

    td {
        padding: 0px !important;
    }

    label {
        width: 100%;
        margin-bottom: 0px;
        font-weight: 600;
        border-bottom: 5px solid white;
        padding: 12px;
        margin-bottom: 10px;
    }
}

.post-feed-empty-label {
    width: 100%;
    font-size: 16px;
    padding: 30px 16px;
    text-align: center;

}