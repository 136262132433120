.login-form-container {
    display: block;
    width: 75%;
    margin: 0 auto;
}

.login-form-input {
    display: block;
    height: 36px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: #797979;
    margin-bottom: 12px;
    outline: none;
    border-radius: 8px;
    border: none;
}

.login-form-input::placeholder {
    color: white;
}