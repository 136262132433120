
.post-image-labels-container {
    padding-right: 6px;
}

.post-image-label {
    font-size: 15px;
    font-weight: 600;

    img {
        height: 18px;
        width: 18px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.post-image-username {
    min-width: 134px;
    float: left;
    text-align: center;
    margin-left: 6px;
}

@media only screen and (min-width : 499px) {
    .post-image {
        height: calc(100vw / 1.155);
    }

    .post-sticker {
        width: calc(100vw / 3);
    }
}

@media only screen and (max-width : 369px) {
    .post-sticker-container {
        left: 33% !important;
    }
}

.post-image {
    height: auto;
    width: 100%;
    background-color: white;
    object-fit: contain;
    image-orientation: from-image;
}

.post-image.img-loading {
    min-height: calc(500px / 1.155);
}

.create-post-image {
    min-height: calc(500px / 1.155);
    border: 1px solid black;
}

.post-image-addbtn {
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #0433ff;
    border: none;
    outline: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
}

.post-sticker-container {
    position: absolute;
    bottom: -60px;
    left: 40%;
}

.post-sticker {
    height: 120px;
    width: calc(500px / 3);
    min-width: 174px;
    border: 2px solid black;
    background-color: white;
    border-radius: 8px;
    object-fit: contain;
}

.post-sticker-viewing {
    background-color: transparent;
    border: unset;
}

.post-sticker-addbtn {
    width: 100%;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #0433ff;
    border: none;
    outline: none;
    position: absolute;
    left: 50%;
    top: 36%;
    transform: translate(-50%);
}

.post-image-removebtn-container {
    position: absolute;
    right: 40px;
    top: 16px;
}

.post-sticker-removebtn-container {
    position: absolute;
    right: 24px;
    top: -14px;
}