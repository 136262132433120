
.admin-outlets-table {
    height: 100%;
    width: 100%;
    border-bottom: 2px solid #ddd;

    thead {
        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: blue;
            float: right;
            margin-right: 12px;
            font-size: 36px;
        }
    }
}

.admin-outlets-cell {
    
    border-top: 2px solid #ddd;
    * {
        cursor: pointer;
    }
}

.admin-outlets-cell-content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 45px;

    div {
        width: 120px;
        font-size: 16px;
        word-wrap: break-word;
    }

    span {
        font-weight: 600;
    }
}