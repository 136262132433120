
.search-result-table {
    height: 100%;
    width: 100%;

    td {
        padding: 8px 12px;
    }

    a { 
        text-decoration: none !important;
        background-color: transparent !important;
    }

    * {
        cursor: pointer;
    }
}