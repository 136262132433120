.notification-tablecell {
    height: 100%;
    width: 100%;
    position: relative;
}

.notification-tr-content {
    display: flex;
    width: 88%;
    align-items: center;
    overflow: hidden;
}

.notification-tr-img {
    height: auto;
    min-width: 50px;

    img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
}

.notification-tr-label {
    display: block;
    width: 100%;
    color: black;
    font-size: 16px;
    margin: 0;
    line-height: 20px;
    word-break: break-word;
}

.notification-tr-label-username {
    font-weight: 600;
}

.notification-tr-time {
    color: black;
    font-size: 14px;
}

.notification-tr-label-container {
    margin-left: 12px;
}

.notification-tr-delete {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 24px;
    color: black;
    border: none;
    outline: none;
    background: unset;
}