
.about-page-container {
    color: white;
    padding-bottom: 50px;
    padding-left: 12px;
    padding-right: 12px;

    .about-page-img {
        min-height: 200px;
        height: auto;
        width: 100%;
    }
}