
.selection-table {
    height: 100%;
    width: 100%;
}

.selection-tablecell {
    border-bottom: 4px solid #cacac9;
    padding: 16px 0px;
}

.selection-tc-head {
    text-align: center;
    padding: 0px 16px;
}

.selection-head-title {
    display: block;
    font-weight: 600;
    font-size: 18px;
}

.selection-head-subtitle {
    display: block;
    font-size: 16px;
    margin-bottom: 0px;
}

.selection-tc-img-container {
    padding: 0px 16px;
}

.selection-tc-img {
    width: 100%;
}

.selection-tc-select-container {
    padding: 0px 16px;

    label {
        font-size: 16px;
        font-weight: 600;
    }

    img {
        height: 40px;
        width: auto;
        float: right;
    }
}

.selection-tc-selectbtn {
    float: right;
    outline: none;
    border: none;
    background-color: transparent;
    color: #0433ff;
    font-size: 16px;
    font-weight: 600;
}

.selection-modal-route {
    display: block;
    font-size: 16px;
    text-decoration: none !important;
    background: transparent !important;
    color: #007bff !important;
}