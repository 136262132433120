
.notification-page-container {
    min-height: 100vh;
    background-color: #cacac9;
}

.notifications-head-container {
    display: block;
    width: 100%;
    color: black;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    background-color: #cacac9;
    padding: 22px;
    margin-bottom: 1px;
}

.notifications-table {
    height: 100%;
    width: 100%;

    td {
        padding: 8px 12px;
    }

    a { 
        text-decoration: none !important;
        background-color: transparent !important;
    }

    * {
        cursor: pointer;
    }
}