
$profileCircleSize: 225;

$centerProfileCircle: calc($profileCircleSize / 2);

.profile-image-full-container {
    height: calc(100vh / 2.18);
    width: 100%;
    position: relative;
}

.profile-image-full-badge {
    height: calc(100vh / 2);
    width: 100%;
    object-fit: contain;
}

.profile-image-full-user {
    height: unquote($profileCircleSize + "px");
    width: unquote($profileCircleSize + "px");
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    border: 1px solid black;
    border-radius: 50%;
    background-color: white;
    box-shadow: 4px 4px 4px 0px #00000061;

    .awsimg-container {
        img {
            height: unquote($profileCircleSize + "px");
            width: unquote($profileCircleSize + "px");
            border-radius: 50%;
        }
    }
}

.profile-edit-button {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 7%;
    top: 15px;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    
    img {
        height: 30px;
        width: auto;
        margin: 0 auto;
        display: block;
    }
}

.profile-edit-badge-container {
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 42px;
}

.profile-edit-badge-btn {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: #0433ff;
    font-weight: 600;
    font-size: 21px;
    vertical-align: middle;
}

.profile-image-file-field-label {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: unquote($centerProfileCircle + "px");
    z-index: 1;
    color: #0433ff;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 0px;
    min-width: 135px;

    input {
        display: none;
    }
}

.profile-full-extra-stat-left {
    position: absolute;
    bottom: 2%;
    left: calc(225px - 215px);

    img {
        display: block;
        height: 55px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3px;
    }

    label {
        display: block;
        width: 88px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
    }
}

.profile-full-extra-stat-right {
    position: absolute;
    bottom: 2%;
    right: calc(225px - 215px);

    label {
        width: 88px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
    }
}

.profile-image-full-user-remove-container {
    position: absolute;
    right: 9%;
    bottom: 6px;
}

/* iPhone 5 */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .profile-image-full-container {
        height: calc(100vh / 1.54) !important;
    }
}


/* iPhone 6 */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .profile-image-full-container {
        height: calc(100vh / 1.72) !important;
    }
}

/* iPhone X */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .profile-image-full-container {
        height: calc(100vh / 1.74) !important;
    }
}



/* iPhone 12 */
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
    .profile-image-full-container {
        height: calc(100vh / 1.94) !important;
    }
}

/* Pixel 4 XL */
// @media only screen and (min-width: 412px) and (max-width: 767px) { 
//     .profile-image-full-container {
//         height: calc(100vh / 1.94) !important;
//     }
// }

/* iPhone 6,7,8 + */
@media only screen 
    and (min-device-width: 414px) 
    and (max-device-width: 736px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
    .profile-image-full-container {
        height: calc(100vh / 1.84) !important;
    }
}

/* iPhone 11 */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
    .profile-image-full-container {
        height: calc(100vh / 2.01) !important;
    }
}

/* iPhone XS Max */
@media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
    and (-webkit-device-pixel-ratio : 3) { 
    .profile-image-full-container {
        height: calc(100vh / 2.01) !important;
    }
}


/* iPhone 12 Pro Max */
@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) {
    .profile-image-full-container {
        height: calc(100vh / 2.055) !important;
    }
}





