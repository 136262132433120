
.admin-outlets-codes-table {
    height: 100%;
    width: 100%;
    border-bottom: 2px solid #ddd;

    thead {
        .admin-outlets-thead-content {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        button {
            border: none;
            outline: none;
            background-color: transparent;
            color: blue;
            float: right;
            margin-right: 12px;
            font-size: 36px;
        }
    }
}

.admin-outlets-search-bar {
    height: 40px;
    width: 280px;
    border-radius: 8px;
    outline: none;
    border: none;
    padding: 0px 12px;
    background-color: #474b4a;
    color: white;
    font-size: 16px;
}

.admin-outlets-search-bar::placeholder {
    color: white;
}

.admin-outlets-codes-cell {
    padding: 0px 12px;
    border-top: 2px solid #ddd;
    * {
        cursor: pointer;
    }
}

.admin-outlets-codes-cell-content {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 45px;
    

    div {
        width: auto;
        font-size: 16px;
        word-wrap: break-word;
    }

    span {
        font-weight: 600;
    }

    label {
        margin-bottom: 0px;
        word-break: break-word;
    }
}