
.search-bar-container {
    display: block;
    width: 100%;
    padding: 6px 0px;
}

.search-bar {
    display: block;
    height: 35px;
    width: 92%;
    margin: 0 auto;
    border-radius: 6px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    outline: none;
    padding-left: 40px;

    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 2%;

    box-shadow: 2px 2px 2px 0px #00000061;
}

.search-bar::placeholder {
    color: white;
}

.search-bar-container {
    display: inline-flex;

    button {
        width: auto;
        background-color: unset;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 16px;
        color: #0433ff;
        margin-right: 10px;
    }
}

.search-bar-results {
    .search-bar {
        margin-left: 20px;
    }
}