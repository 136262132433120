.report-page-container {
    min-height: 100vh;
    background-color: #cacac9;
    padding: 12px 6px;
    position: relative;
}

.report-page-label {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}

.report-page-form {
    margin-top: 24px;
    padding: 0px 12px;

    div {
        min-height: 80px;
        width: 100%;
    }

    label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    select {
        background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%;
    }

    select, textarea {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }



    textarea {
        height: 100px;
        padding: 12px;
    }

    textarea::placeholder {
        color: white;
    }
}

.report-user-table {
    height: 100%;
    width: 100%;

    tr * {
        cursor: pointer;
    }
}

.report-user-tablecell {
    height: 100%;
    width: 100%;
    position: relative;
}

.report-user-tr-content {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

.report-user-tr-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.report-user-tr-userlabel {
    display: block;
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.report-user-tr-label-container {
    margin-left: 12px;
    overflow: hidden;
}

.report-user-tr-unfollow {
    position: absolute;
    right: 20px;

    img {
        height: 26px;
        width: auto;
    }
}