
.store-category-item-cell {
    height: auto;
    width: 100%;
    padding: 16px;

    hr {
        border: 1px solid black;
        margin: 0px;
    }
}

.store-category-item-cell-padlock {
    width: 50px !important;
    img {
        height: 50px;
        width: auto;
        cursor: pointer;
        object-fit: contain;
    }
}

.store-category-item-cell-grid {
    display: flex;
    justify-content: space-around;
}

.store-category-item-cell-cloudset-1 {

    .awsimg-container {
        display: inline-flex;
        min-width: 50%;
        justify-self:center; 
    }
    
    img {
        width: 126px;
        object-fit: contain;
        margin: 15px auto;
    }
}

.store-category-item-cell-cloudset-2 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 25px;
    
    img {
        height: 60px;
        width: 126px;
        object-fit: contain;
    }
}

.store-category-item-cell-cloudset-3 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px 0px;
    
    .awsimg-container {
        img {
            max-height: 160px;
            width: 280px;
            object-fit: contain;
            margin: 10px 0;
        }
    }
}


.store-category-item-cell-multiplier {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 5px;
    
    label {
        margin-bottom: 0px;
    }

    .store-category-item-cell-buy {
        margin-bottom: 0px;
    }
}

.store-category-item-dialog-text {

    .dialog-modal-body-content-head {
        line-height: 32px;
    }
}

.store-category-item-cell-title {
    display: flex;
    font-size: 15px;
    width: 100%;

    div {
        width: 100%;
    }

    label {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        font-weight: 600;
    }

    span {
        display: flex;
        justify-content: center;
    }
}

.store-category-item-cell-desc {
    display: block;
    width: 100%;

    label {
        width: 100%;
        font-size: 15px;
        word-wrap: break-word;
        white-space: pre-line;
    }
}

.store-category-item-cell-buy {
    color: #0433ff;
    text-align: right;
    margin-top: 5px;
    margin-bottom: 15px;

    label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
        cursor: pointer
    }

    span {
        display: block;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;

        span {
            display: inline-block;
            font-weight: 500;
        }
    }

    .owned-item {
        cursor: default;
        color: #37b647;
    }
}