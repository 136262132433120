

.dialog-modal {
    top: 28% !important;
    padding-right: 0px !important;

    .modal-content {
        border-radius: 13px;
    }
}

.dialog-modal-body {
    min-height: 240px;
    border: 3px solid black;
    border-radius: 12px;
    background-color: #dbdde0;
    padding: 16px 12px !important;
}

@media only screen and (min-width : 414px) {
    .dialog-modal-body {
        padding: 36px 12px 16px 13px !important;
    }

    .dialog-modal-body-img {
        position: absolute;
        left: 4%;
        top: 30px;
    }

    .dialog-modal-body-content {
        margin-left: 12px;
    }
}

.dialog-modal-body-img {
    display: block;
    height: 70px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    //box-shadow: 4px 4px 4px 0px #00000061;
}

.dialog-close-icon {
    height: 35px !important;
    width: auto;
    position: absolute !important;
    right: 4%;
    top: 30px;
    z-index: 1;
}

.dialog-modal-body-content {
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    position: relative;

    .dialog-modal-body-content-head {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 28px;
    }
}

.dialog-modal-body-confirm {
    display: block;
    width: 100%;
    margin-top: 18px;

    button {
        background-color: transparent;
        outline: none;
        border: 1px solid gray;
        width: 100%;
        padding: 4px 0px;
        font-size: 16px;
        border-radius: 4px;
    }

    button:hover {
        background-color: #cacbcc;
    }
}