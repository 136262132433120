
.admin-users-options-container {
    display: block;
    height: 60px;
    width: 100%;
    margin-bottom: 30px;
    padding: 0px 16px;
    
    button {
        display: inline-block;
        height: 100%;
        width: 50%;
        border: none;
        outline: none;
        background: transparent;
        line-height: 22px;
        font-size: 16px;
        color: black;

        span {
            font-weight: 600;
            font-size: 22px;
        }
    }

    .pro-active {
        border-bottom: 2px solid black;
    }

    .pro-single {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.admin-users-table {
    height: 100%;
    width: 100%;

    tr * {
        cursor: pointer;
    }

}

.admin-users-tablecell {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 8px 0px;
}

.admin-users-tr-content {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

.admin-users-tr-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.admin-users-tr-userlabel {
    display: block;
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.admin-users-tr-label-container {
    margin-left: 12px;
    overflow: hidden;
}

.admin-users-tr-viewreport {
    position: absolute;
    right: 60px;
}