
.redeemed-container {
    min-height: 100vh;
    background-color: #cacac9;
    padding: 20px 16px;
}

.redeemed-table {
    margin: 0 auto;
}

.redeemed-back-button {
    font-weight: 600;
    font-size: 16px;
    color: #0433ff;
    background: transparent;
    outline: none;
    border: none;
    margin-bottom: 12px;
}

.redeemed-outlets-table {
    height: 100%;
    width: 100%;
}

.redeemed-outlet-cell-title {
    display: block;
    font-size: 15px;
    width: 100%;

    div {
        width: 100%;
    }

    label {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        font-weight: 600;
    }

    span {
        display: flex;
        justify-content: center;
    }
}

.redeemed-outlet-cell-image-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 15px 0px;
    
    img {
        max-height: 140px;
        width: 100%;
        object-fit: contain;
    }
}

.redeemed-outlet-cell-code-info {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px 40px;

    label {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        word-wrap: break-word;
    }

    span {
        display: block;
        font-weight: 500;
        margin-top: 20px;
    }

    a {
        display: block;
        font-weight: 500;

    }
}