.comment-page-container {
    min-height: 100vh;
    background-color: #cacac9;
}

.comment-page-seperator {
    border-top: 3px solid white;
}

.comment-page-viewpost-container {
    display: block;

    button {
        display: block;
        margin-left: auto;
        margin-right: 22px;
        background-color: transparent;
        outline: none;
        border: none;
        color: #0433ff;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
    }
}