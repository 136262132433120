
.contact-page-container {
    color: white;
    text-align: center;

    label {
        font-size: 20px;
    }

    p {
        margin: 40px 0px;
        font-size: 15px;
    }

    a {
        color: white !important;
        text-decoration: underline;
        background: transparent !important;
        font-size: 15px;
    }
}