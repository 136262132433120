.admin-outlets-form {
    margin-top: 24px;
    padding: 0px 12px;

    div {
        min-height: 80px;
        width: 100%;
    }

    label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
    }


    input, textarea {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
    }

    textarea {
        height: 100px;
        padding: 12px;
        resize: none;
    }


    input::placeholder {
        color: white;
    }

    textarea::placeholder {
        color: white;
    }

    select {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
    }

    .admin-outlets-form-codes {
        min-height: unset;
        margin: 5px auto;
        text-align: center;

        button {
            background: transparent;
            color: #0433ff;
            outline: none;
            border: none;
            padding: 0;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .admin-outlets-location-list {
        width: 100%;
        margin-top: 25px;

        span {
            display: block;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 0px;
            text-decoration: underline;
            margin-bottom: 10px;
        }

        div {
            display: inline-flex;
            min-height: 28px !important;
            align-items: center;
            margin-bottom: 5px;
        }

        label {
            max-width: 80%;
        }

        button {
            outline: none;
            border: none;
            width: 64px;
            height: 28px;
            border-radius: 8px;
            box-shadow: 0px 4px 3px 0px #000;
            color: white;
            background-color: #bd1b11;
            -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            text-shadow: 1px 1px 1px #000000;
            letter-spacing: .3px;
            margin-left: auto;
            text-align: center;
            font-size: 13px;
            padding: 0;
        }

    }

    .admin-outlets-location-btn {
        background: rgba(157,218,164,1);
        background: -moz-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(157,218,164,1)), color-stop(17%, rgba(93,195,105,1)), color-stop(27%, rgba(55,182,71,1)), color-stop(100%, rgba(55,182,71,1)));
        background: -webkit-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
        background: -o-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
        background: -ms-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
        background: linear-gradient(to bottom, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ddaa4', endColorstr='#37b647', GradientType=0 );
    
        display: block;
        height: 32px;
        width: 58px;
        font-size: 13px;
        font-family: "HRB";
        outline: none;
        border-radius: 6px;
        border: none;
        color: white;
        text-align: center;
        padding: 0;
    
        -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
        text-shadow: 1px 1px 1px #000000;
        letter-spacing: .3px;
        margin-top: 15px;
    }

}

