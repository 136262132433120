@font-face {
    font-family: 'BRADHI';
    font-style: normal;
    font-weight: normal;
    src: local('BRADHI'), url('./Fonts/BRADHI.ttf') format('truetype');
}

@font-face {
    font-family: 'HRB';
    font-style: normal;
    font-weight: normal;
    src: local('HRB'), url('./Fonts/HRB.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: Arial !important;
    font-style: normal;
    font-variant: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-app-container {
    background: black;
    min-height: 100vh;
    min-width: 100vw;
    
}

.sub-app-container {
    max-width: 500px;
    margin: 0 auto;
}

.mar-vert-12 {
    margin: 12px 0px;
}

.mar-vert-20 {
    margin: 20px 0px; 
}

.mar-vert-24 {
    margin: 24px 0px;
}

.mar-vert-36 {
    margin: 36px 0px;
}

.mar-vert-60 {
    margin: 60px 0px;
}

.img-loading {
    background: lightgray;
    background-size: 800px 104px;
    height: 100px;
    position: relative;
}

.img-loading-spinner {
    height: 50px;
    position: absolute !important;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
}