
.store-category-top-container {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 15px;

    label {
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        text-align: center;

    }

    button {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 16px;
        font-weight: 600;
        outline: none;
        background: none;
        border: none;
        -webkit-appearance: none;
        color: #0433ff !important;
    }
}

.store-category-items-table {
    height: 100%;
    width: 100%;
}