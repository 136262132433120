
.post-actions-container {
    display: block;
    width: 100%;
    height: 30px;
    padding: 5px 20px;
}

.post-action-like {
    display: inline-block;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.post-action-like-label {
    display: inline-block;
    font-size: 16px;
    color: black;
    margin-left: 6px;
    cursor: pointer;
}

.post-action-elip {
    height: auto;
    width: 25px;
    cursor: pointer;
}