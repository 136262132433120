
.profile-relations-page-container {
    min-height: 100vh;
    background-color: #cacac9;
}

.profile-posts-page-container {
    min-height: 100vh;
    background-color: #cacac9;
}

.profile-relations-username-container {
    display: block;
    width: 100%;
    color: black;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    padding: 22px;
    margin-bottom: 1px;
}

.profile-relations-options-container {
    display: block;
    height: 60px;
    width: 100%;
    margin-bottom: 30px;
    padding: 0px 16px;
    
    button {
        display: inline-block;
        height: 100%;
        width: 50%;
        border: none;
        outline: none;
        background: transparent;
        line-height: 22px;
        font-size: 16px;
        color: black;

        span {
            font-weight: 600;
            font-size: 22px;
        }
    }

    .pro-active {
        border-bottom: 2px solid black;
    }

    .pro-single {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.profile-relations-table {
    height: 100%;
    width: 100%;

    td {
        padding: 8px 12px;
    }

    a { 
        text-decoration: none !important;
        background-color: transparent !important;
    }

    * {
        cursor: pointer;
    }
}

