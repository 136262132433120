.purchase-history-page-container {
    min-height: 100vh;
    background-color: #cacac9;

    label {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 40px;
        font-size: 16px;
        font-weight: 600;
    }
}