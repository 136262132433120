.landing-image-container {
    display: block;
}

.landing-image {
    display: block;
    height: 260px;
    width: 160px;
    object-fit: cover;
    border-radius: 8px;
    margin: 0 auto;
}

.landing-image-label {
    display: block;
    font-family: "BRADHI";
    font-size: 25px;
    margin-top: 28px;
    color: white;
    text-align: center;
}