
.admin-options-table {
    height: 100%;
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0px 12px;

    thead {
        
        * {
            cursor: default;
        }

        div {
            height: auto;
        }
    }

    tfoot {
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border: none;
            width: 115px;
            height: 45px;
            border-radius: 8px;
            box-shadow: 0px 4px 3px 0px #000;
            color: white;
            background-color: #bd1b11;
            -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
            text-shadow: 1px 1px 1px #000000;
            letter-spacing: .3px;
            margin: 10px auto;
            text-align: center;
            font-size: 16px;
            padding: 0;
        }
    }
}

.admin-option-tablecell {
    
    * {
        cursor: pointer;
    }

    div {
        display: flex;
        align-items: center;
        min-height: 40px;
        background-color: white;
        border: 2px solid black;
        border-radius: 8px;
        padding: 0px 14px;
    }

    label {
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 600;
    }

    span {
        float: right;
        margin-left: -16px;
        color: #0433ff;
    }
}

.admin-metrics-tablecell {

    .metrics-refresh-div {
        height: auto;
        background: #cacac9;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 600;

        button {
            background: none;
            outline: none;
            border: none;
            color: #0433ff;
            font-size: 16px;
            margin-bottom: 10px;
            text-decoration: underline;
        }
    }

    .metrics-container {
        min-height: unset;
        background-color: unset;
        border: unset;
        border-radius: unset;
        padding: unset;
    }
    
    div {
        display: block;
        min-height: 40px;
        background-color: white;
        border: 2px solid black;
        border-radius: 8px;
        padding: 10px 4%;
    }

    label {
        height: 30px;
        width: 100%;
        margin-bottom: 0px;
        font-size: 15px;
    }

    span {
        float: right;
    }


}