.admin-page-container {
    min-height: 100vh;
    background-color: #cacac9;
    padding: 12px 6px;
    position: relative;
}

.admin-page-label {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}