.conversation-tablecell {
    height: 100%;
    width: 100%;
    position: relative;
}

.conversation-tr-content {
    display: flex;
    width: 60%;
    align-items: center;
}

.conversation-tr-img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.conversation-tr-label {
    display: block;
    width: 100%;
    color: black;
    font-size: 16px;
    margin: 0;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        font-weight: 600;
    }

    img {
        height: 18px;
        width: 18px;
        object-fit: contain;
        margin-left: 6px;
    }
}

.conversation-tr-lastmessage {
    display: block;
    max-width: 235px;
    color: black;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.conversation-tr-time {
    color: black;
    font-size: 14px;
}

.conversation-tr-label-container {
    margin-left: 12px;
    overflow: hidden;
}

.conversation-tr-delete {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 24px;
    color: black;
    border: none;
    outline: none;
    background: unset;
}