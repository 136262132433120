
.terms-page-container {
    color: white;
    padding-bottom: 50px;
    padding-left: 12px;
    padding-right: 12px;

    label {
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
    }

    p {
        text-align: left;
    }

    span {
        font-weight: 600;
    }

    hr {
        border-color: white;
        margin: 50px 0px;
    }
}