
.store-page-container {
    min-height: 100vh;
    background-color: #cacac9;
    padding: 12px;
    position: relative;
}

.store-page-status-container {
    display: block;
    width: 100%;
    margin-top: 60px;
    padding: 0px 12px;

    img {
        display: block;
        margin: 0 auto;
        height: 120px;
        width: auto;
    }

    label {
        display: block;
        width: 100%;
        margin-top: 22px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }
}