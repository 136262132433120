.landing-register-link-container {
    display: block;
}

.landing-register-link-hr-container {
    width: 100%;
    position: relative;

    hr {
        width: 100%;
        border-top: 1px solid white;
    }

    label {
        height: auto;
        width: 65px;
        font-size: 15px;
        color: white;
        position: absolute;
        left: 50%;
        bottom: -8px;
        transform: translate(-50%);
        text-align: center;
        background-color: black;
        margin-bottom: 0px;
    }
}

.register-link-container {
    width: 100%;
    margin-top: 58px;
}

.landing-register-link {
    background: rgba(157,218,164,1) !important;
    background: -moz-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%) !important;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(157,218,164,1)), color-stop(17%, rgba(93,195,105,1)), color-stop(27%, rgba(55,182,71,1)), color-stop(100%, rgba(55,182,71,1))) !important;
    background: -webkit-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%) !important;
    background: -o-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%) !important;
    background: -ms-linear-gradient(top, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%) !important;
    background: linear-gradient(to bottom, rgba(157,218,164,1) 0%, rgba(93,195,105,1) 17%, rgba(55,182,71,1) 27%, rgba(55,182,71,1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ddaa4', endColorstr='#37b647', GradientType=0 );

    display: block;
    height: 38px;
    width: 40%;
    min-width: 192px;
    text-align: center;
    font-size: 15px;
    font-family: "HRB";
    outline: none;
    border-radius: 6px;
    border: none;
    color: white !important;
    text-decoration: none !important;
    padding-top: 8px;

    -webkit-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.51);
    text-shadow: 1px 1px 1px #000000;
    letter-spacing: .3px;
    margin: 0 auto;
}