$profileCircleSize: 75;

.points-stat-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.points-profile-full-container {
    display: inline-block;
    height: 134px;
    width: 134px;
    position: relative;
    margin-left: -10px;
}

.points-image-full-badge {
    height: 134px;
    width: 134px;
    object-fit: cover;
}

.points-image-full-user {
    height: unquote($profileCircleSize + "px");
    width: unquote($profileCircleSize + "px");
    border-radius: 50%;
    border: 1px solid black;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0px;
    box-shadow: 4px 4px 4px 0px #00000061;

    .awsimg-container {
        img {
            height: unquote($profileCircleSize + "px");
            width: unquote($profileCircleSize + "px");
            border-radius: 50%;
        }
    }
}

.points-stat-available {
    margin-top: 65px;
    margin-left: -15%;
    label {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0px;
        line-height: 25px;
    }

    span {
        font-weight: 600;
        font-size: 42px;
    }
}

.points-stat-total {
    margin-top: 15px;

    label {
        font-size: 16px;
        text-align: center;
        margin-bottom: 0px;
        line-height: 25px;
    }

    span {
        font-weight: 600;
    }

    img {
        display: block;
        height: 50px;
        margin: 0 auto;
    }
}