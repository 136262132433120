
.admin-badge-form {
    margin-top: 24px;
    padding: 0px 12px;

    div {
        height: 80px;
        width: 100%;
    }

    label {
        display: block;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    input {
        height: 38px;
        width: 100%;
        border-radius: 8px;
        outline: none;
        border: none;
        background-color: #474b4a;
        color: white;
        font-size: 15px;
        padding: 0px 10px;
    }

    input::placeholder {
        color: white;
    }


}