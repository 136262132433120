
.circle-count {
    display: inline-block;
    height: 22px;
    width: 22px;
    background-color: #37b647;
    border-radius: 50%;
    color: white;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    position: relative;
    top: -3px;
    left: 14px;
    text-indent: -1px;
    line-height: 22.9px;
}

.circle-dot {
    height: 8px;
    width: 8px;
    position: absolute;
    top: -3px;
    right: -3px;
    left: unset;
}