.register-form-content {
    display: table;
    margin: 10px auto;
}

.register-step-label {
    display: block;
    font-size: 19px;
    color: white;
    text-align: center;
}

.register-form-input {
    display: block;
    height: 36px;
    width: 226px;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: #797979;
    margin-bottom: 12px;
    outline: none;
    border-radius: 8px;
    border: none;
}

.register-form-input::placeholder {
    color: white;
}

.register-step-checkbox-label {
    display: inline-block;
    font-size: 19px;
    color: white;
    text-align: center;
    margin: 0 12px;
    vertical-align: middle;
}

.register-sendagain-link {
    font-size: 15px;
    color: white !important;
    text-decoration: underline !important;
    border: none;
    background: transparent !important;
    cursor: pointer;
}

.register-age-checkbox {
    zoom: 2;
    transform: scale(0.888);
    -ms-transform: scale(0.888);
    -webkit-transform: scale(0.888);
    -o-transform: scale(0.888);
    -moz-transform: scale(0.888);
    transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    vertical-align: middle;
  }

  .register-timer-container {

    margin: 12px 0px;

    label {
        display: block;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 15px;
    }
}

.register-username-span-placeholder {
    color: black;
    position: absolute;
    top: 10px;
    left: 60px;
    font-size: 16px;
    font-weight: 600;
}

.register-phone-input-btn {
    background-color: #797979 !important;
    border: none !important;
    border-right: 1px solid black !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    .selected-flag {
        background-color: #797979 !important;  
    }
}

.register-phone-input {
    display: block;
    height: 42px !important;
    width: 246px !important;
    text-align: left;
    font-size: 16px !important;
    color: white !important;
    background-color: #797979 !important;
    margin-bottom: 12px !important;
    outline: none !important;
    border-radius: 8px !important;
    border: none !important;
    padding-left: 65px !important;
    outline: none !important;
}

.register-phone-input::placeholder {
    color: white;
}